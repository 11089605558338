<template>
  <CommonLink
    :data-testid="`AuctionListItemTitle${link ? 'Link' : ''}`"
    :underline="asLink"
    class="text-left"
    :target="asLink ? '_self' : ''"
    :href="asLink ? link : undefined"
    @click="onClick"
  >
    {{ title }}
  </CommonLink>
</template>

<script setup lang="ts">
import CommonLink from '@autobid/ui/components/common/Link.vue'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { inject } from 'vue'

const { title } = inject('auction') as DisplayingAuction

const link: ComputedRef<string | undefined> = inject('link')

interface Props {
  asLink?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  asLink: true
})

const onClick = (e: Event) => {
  if (!props.asLink) {
    return
  }
  e.preventDefault()
}
</script>
